.pagination {
	display: flex;
	list-style: none;
	width: 100%;
	justify-content: center;

	li.active {
		a {
			color: #FACD49;
			pointer-events: none;
			cursor: default;
		}
	}

	a {
		font-size: 18px;
		line-height: 1;
		display: block;
		color: $black-color;
		padding: 4px;
		margin: 0 4px;
	}
}