.hotel-check {
	position: relative;
	list-style: none;
	padding: 0;

	&__item {
		display: flex;
		font-size: vwm(18px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			font-size: vwt(36px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(18px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			font-size: 18px;
			margin-bottom: 16px;
		}

		.icon {
			margin-right: vwm(8px);
			font-size: vwm(24px);
			color: #FBBC04;

			@include minW(640) {
				font-size: vwt(24px);
				margin-right: vwt(16px);
			}

			@include minW(992) {
				font-size: vwd(24px);
				margin-right: vwd(8px);
			}

			@include minW(1440) {
				font-size: 24px;
				margin-right: 8px;
			}
		}

		&--no {
			color: #808080;

			.icon {
				color: #808080;
			}
		}
	}

	&--row {
		display: flex;
		flex-wrap: wrap;

		.hotel-check {
			&__item {
				max-width: 50%;
				flex-basis: 50%;
			}
		}
	}
}