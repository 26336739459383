.card-cart {
	position: relative;

	&__head {
		.card-cart {
			&__row {
				margin-bottom: 0;
			}

			&__item {
				font-weight: 700;
				font-size: vwm(20px);
				display: none;

				@include minW(640) {
					font-size: vwt(20px);
				}

				@include minW(992) {
					font-size: vwd(20px);
					display: flex;
				}

				@include minW(1440) {
					font-size: 20px;
				}
			}
		}
	}

	&__item {
		background-color: #f5f5f5;
		padding: vwm(32px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
			margin-bottom: vwt(16px);
		}

		@include minW(992) {
			padding: vwd(32px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			padding: 32px;
			margin-bottom: 16px;
		}
	}

	&__row {
		width: 100%;
		margin-bottom: vwm(16px);

		@include minW(640) {
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			display: flex;
			align-items: center;
			margin-bottom: vwd(32px);
		}

		@include minW(1440) {
			margin-bottom: 32px;
		}

		.checkbox {
			margin-bottom: 0;
		}
	}

	&__col {
		&--room-name {
			@include minW(992) {
				width: 40%;
				flex-basis: 40%;
			}
		}

		&--unit-price {
			@include minW(992) {
				width: 17%;
				flex-basis: 17%;
			}
		}

		&--quantity {
			@include minW(992) {
				width: 17%;
				flex-basis: 17%;
			}
		}

		&--total-amount {
			@include minW(992) {
				width: 17%;
				flex-basis: 17%;
			}
		}

		&--action {
			@include minW(992) {
				width: 9%;
				flex-basis: 9%;
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
		margin-bottom: vwm(16px);

		@include minW(640) {
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			margin-bottom: 0;
		}
	}

	&__title {
		display: block;

		@include minW(992) {
			display: none;
		}
	}

	&__control {
		.card-cart {
			&__item {
				@include minW(992) {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}

	&__offer {
		.tour-offer {
			margin-bottom: 0;
			background-color: #fff;
		}
	}
}

.room-name {
	display: flex;
	align-items: center;

	&__thumb {
		width: 40%;
		flex-basis: 40%;
		overflow: hidden;
		margin-right: 8px;

		@include minW(640) {
		}

		@include minW(992) {
			width: 20%;
			flex-basis: 20%;
		}

		@include minW(1440) {
		}

		.img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__text {
		width: 60%;
		flex-basis: 60%;
		font-weight: 700;

		@include minW(640) {
		}

		@include minW(992) {
			width: 80%;
			flex-basis: 80%;
		}

		@include minW(1440) {
		}
	}
}

.package-option {
	&__body {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		@include minW(992) {
			margin-right: 64px;
		}

		&:last-child {
			margin-right: 0;
		}

		.checkbox {
			&__text {
				font-size: vwm(20px);

				@include minW(640) {
					font-size: vwt(40px);
				}

				@include minW(992) {
					font-size: vwd(20px);
				}

				@include minW(1440) {
					font-size: 20px;
				}
			}
		}
	}
}

.control-left {
	margin-bottom: 16px;

	@include minW(992) {
		margin-bottom: 0;
	}

	.checkbox {
		margin-bottom: 0;
		margin-right: 32px;
	}
}

.control-right {
	@include minW(992) {
		display: flex;
		align-items: center;
	}

	&__text {
		margin-bottom: 16px;
		@include minW(992) {
			margin-bottom: 0;
			margin-right: 16px;
		}
	}
}
