.signin {
	background-color: #E1D1B2;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 48px 64px;

	&__other {
		display: flex;
		margin-bottom: 0;

		a {
			margin-left: 16px;
			color: #808080;
			display: block;
		}
	}
}