.itinerary {
	position: relative;

	&__item {
		&:last-child {
			.itinerary {
				&__content {
					&::before {
						display: none;
					}
				}
			}
		}
	}

	&__title {
		position: relative;
		margin-bottom: 0;
		font-size: vwm(20px);
		padding-left: vwm(40px);

		@include minW(640) {
			font-size: vwt(20px);
			padding-left: vwt(40px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			padding-left: vwd(40px);
		}

		@include minW(1440) {
			font-size: 20px;
			padding-left: 40px;
		}

		.icon-svg {
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	&__content {
		padding: 8px 0 0 40px;
		position: relative;
		margin-bottom: 8px;

		&::before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			position: absolute;
			left: 12px;
			top: 0;
			border-left: 1px dashed #808080;
		}

		p {
			margin-bottom: 4px;
		}
	}
}

.package-vido {
	&__item {
		margin-bottom: 16px;
	}
}
