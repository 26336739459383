.card-avaiable {
	position: relative;
	background: rgba(142, 16, 34, 0.05);
	border-radius: 20px;

	&__head {
		padding: 16px 32px;
		border-bottom: 1px solid #808080;
		display: none;

		@include minW(992) {
			display: block;
		}
	}

	&__recommended {
		margin-bottom: 0;
	}

	&__body {
		padding: vwm(16px) vwm(32px);

		@include minW(640) {
			padding: vwt(16px) vwt(32px);
		}

		@include minW(992) {
			padding: vwd(16px) vwd(32px);
		}

		@include minW(1440) {
			padding: 16px 32px;
		}

		.btn {
			font-size: vwm(16px);
			padding: vwm(16px) vwm(32px);

			@include minW(640) {
				font-size: vwt(16px);
				padding: vwt(16px) vwt(32px);
			}

			@include minW(992) {
				font-size: vwd(16px);
				padding: vwd(16px) vwd(32px);
			}

			@include minW(1440) {
				font-size: 16px;
				padding: 16px 32px;
			}
		}
	}

	&__title {
		position: relative;

		&::after {
			content: '';
			display: block;
			background: url('../img/icon-arrow-right.svg') no-repeat center;
			background-size: cover;
			width: 32px;
			height: 32px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__price {
		span {
			display: block;
		}
	}

	&__discount {
		color: #007549;
		margin-bottom: 0;
		margin-top: 32px;
	}

	&__date {
		position: relative;
		margin-bottom: vwm(16px);
	}

	+ .card-avaiable {
		margin-top: 32px;
	}

}
