.footer {
	position: relative;
	background-color: $dark-color;
	color: $yellow-color;
	padding: 64px 0;
	overflow: hidden;

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -16px;
	}

	&__col {
		width: 40%;
		flex-basis: 40%;
		padding: 0 16px;
	}

	&__body {
		padding: 48px 0;
	}

	&__copyright {
		text-align: center;
		margin-bottom: 0;
	}

	&__title {
		font-size: 24px;
		margin-bottom: 32px;
	}

	a {
		color: $yellow-color;
		transition: all 0.3s ease-out;

		@include hover {
			color: $white-color;
		}
	}
}

.info {

	@include minW(992) {
		padding-right: 154px;
	}

	&__name {
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 24px;
	}

	&__license {
		span {
			display: block;
			margin-top: 8px;
		}
	}

	p {
		margin-bottom: 24px;
	}
}

.menu {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;

	@include minW(992) {
		flex-direction: column;
	}

	&__item {
		margin-bottom: 32px;
		margin-right: 32px;

		@include minW(992) {
			margin-right: 0;
		}
	}
}

.social {
	display: flex;
	font-size: 28px;
	margin-bottom: 32px;

	@include minW(992) {
		justify-content: space-between;
		padding-right: 154px;
		margin-right: 0;
		margin-bottom: 0;
		font-size: 28px;
	}

	&__link {
		margin-right: 32px;

		@include minW(992) {
			margin-right: 0;
		}
	}
}