.tour-vido {
	position: relative;
	padding: 0 32px;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: vwm(50px);
		width: vwm(556px);
		height: vwm(273px);
		border-radius: 50%;
		background: rgba(225, 209, 178, 0.1);

		@include minW(640) {
			bottom: vwt(100px);
			width: vwt(556px);
			height: vwt(273px);
		}

		@include minW(992) {
			bottom: vwd(100px);
			width: vwd(556px);
			height: vwd(273px);
		}

		@include minW(1440) {
			bottom: 100px;
			width: 556px;
			height: 273px;
		}
	}

	&__head {
		text-align: center;
		font-size: vwm(16px);
		margin-bottom: vwm(32px);
		color: #808080;

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 64px;
		}
	}

	&__title {
		color: #000;
		margin-bottom: 0;
		font-size: vwm(32px);

		@include minW(640) {
			font-size: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(64px);
		}

		@include minW(1440) {
			font-size: 64px;
		}
	}

	.slick {
		position: relative;
		z-index: 3;

		@include minW(992) {
			min-height: vwd(800px);
		}

		@include minW(1440) {
			min-height: 800px;
		}

		&-slide {
			@include minW(992) {
				transition: all 0.3s ease-out;
			}

			&:not(.slick-active) {
				+ .slick-active {
					@include minW(992) {
						margin-top: 0;
					}
				}
			}
		}

		&-active {
			@include minW(992) {
				margin-top: 100px;
			}
		}

		&-current {
			@include minW(992) {
				margin-top: 200px;
			}

			~ .slick-slide {
				@include minW(992) {
					margin-top: 0;
				}
			}

			+ .slick-slide {
				@include minW(992) {
					margin-top: 100px;
				}
			}

			.card-tour {
				&__body {
					@include minW(992) {
						opacity: 1;
					}
				}
			}
		}

		&-arrow {
			position: absolute;
			bottom: 30%;
			font-size: 0;
			width: 42px;
			height: 42px;
			background-color: #fff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 0;
			z-index: 3;

			&::before {
				content: '';
				display: block;
				background: url('../img/icon-arrow-left.svg') no-repeat center;
				width: 15px;
				height: 15px;
			}
		}

		&-prev {
			left: 61%;

			&::before {
				transform: rotate(180deg);
			}
		}

		&-next {
			left: 65%;
		}
	}
}

.card-tour {
	margin-bottom: vwm(32px);

	@include minW(640) {
		margin-bottom: vwt(64px);
	}

	@include minW(992) {
		margin-bottom: 0;
	}

	&__thumb {
		margin-left: 32px;
		margin-right: 32px;
		margin-bottom: 32px;
		display: block;
		height: vwm(200px);

		@include minW(640) {
			height: vwt(400px);
		}

		@include minW(992) {
			height: vwd(200px);
		}

		@include minW(1440) {
			height: 200px;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__body {
		text-align: center;

		@include minW(992) {
			opacity: 0;
		}
	}

	&__title {
		font-weight: 400;
		font-size: vwm(32px);
		margin-bottom: vwmt(32px);

		@include minW(640) {
			font-size: vwt(64px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(32px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 32px;
			margin-bottom: 64px;
		}
	}
}

.tour-detail {
	position: relative;
	padding: 0 vwm(16px);
	overflow: hidden;

	@include minW(640) {
		padding: 0 vwt(32px);
	}

	@include minW(992) {
		padding: 0 vwd(32px);
	}

	@include minW(1440) {
		padding: 0 32px;
	}

	&__button {
		text-align: right;
	}

	.row {
		&__col {
			&--lg-8 {
				order: 0;
			}

			&--lg-4 {
				order: 1;
			}
		}
	}

	.collapse {
		&__head {
			padding-left: 0;
		}

		&__title {
			position: relative;
			font-size: vwm(20px);
			padding-left: vwm(32px);

			@include minW(640) {
				font-size: vwt(40px);
				padding-left: vwt(64px);
			}

			@include minW(992) {
				font-size: vwd(28px);
				padding-left: vwd(32px);
			}

			@include minW(1440) {
				font-size: 28px;
				padding-left: 32px;
			}

			&::before {
				content: '';
				display: block;
				background-color: #c5c5c5;
				border-radius: 4px;
				position: absolute;
				left: 0;
				top: vwm(4px);
				width: vwm(20px);
				height: vwm(20px);

				@include minW(640) {
					top: vwt(8px);
					width: vwt(40px);
					height: vwt(40px);
				}

				@include minW(992) {
					top: vwd(8px);
					width: vwd(24px);
					height: vwd(24px);
				}

				@include minW(1440) {
					top: 8px;
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}

.tour-offer {
	border: 1px solid #e1d1b2;
	border-radius: vwm(10px);
	padding: vwm(16px);
	margin-bottom: vwm(32px);

	@include minW(640) {
		border-radius: vwt(10px);
		padding: vwt(16px);
		margin-bottom: vwt(32px);
	}

	@include minW(992) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: vwd(10px);
		padding: vwd(16px);
		margin-bottom: vwd(32px);
	}

	@include minW(1440) {
		border-radius: 10px;
		padding: 16px;
		margin-bottom: 32px;
	}

	&__list {
		display: flex;
		align-items: center;
	}

	&__item {
		border: 1px solid #fbbc04;
		padding: 8px;
		margin-left: 16px;
		color: #fbbc04;

		&:first-child {
			margin-left: 0;
		}
	}

	&__desc {
		margin-bottom: vwm(16px);

		@include minW(640) {
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			margin-bottom: 0;
		}
	}
}

.tour-available {
	border: 1px solid #e1d1b2;
	position: relative;
	padding: vwm(16px);
	border-radius: vwm(10px);

	@include minW(640) {
		padding: vwt(32px);
		border-radius: vwt(10px);
	}

	@include minW(992) {
		padding: vwd(32px);
		border-radius: vwd(10px);
	}

	@include minW(1440) {
		padding: 32px;
		border-radius: 10px;
	}

	&__body {
		position: relative;

		.datepicker-avaiable {
			top: calc(100% + 16px);
		}
	}

	&__group {
		padding: 16px 16px 16px 50px;
		border: 1px solid #e6e6e6;
		position: relative;
		margin-bottom: 16px;

		&::before {
			content: '\e90c';
			display: block;
			font-family: $icon-font;
			font-size: 24px;
			position: absolute;
			left: 16px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__foot {
		.btn {
			width: 100%;
		}
	}
}

.package-vido {
	.checkbox,
	.radio {
		margin-right: 8px;

		&:last-child {
			margin-left: 0;
		}
	}

	.box-qty {
		display: block;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		border: 1px solid #d9d9d9;
		box-shadow: none;
		width: 100%;
		border-radius: 10px;
	}
}

.tour-map {
	width: 100%;

	iframe {
		display: block;
		width: 100%;
		min-height: 300px;
	}
}

.transportation {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 8px;

	&__item {
		display: none;
		margin-right: 16px;
	}

	&__checkbox {
		display: flex;
		align-items: flex-start;

		.icon-svg {
			margin-right: 8px;
		}
	}
}

.select-hotel {
	&__item {
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		padding: 16px;
		display: none;
		background-color: #fbbc04;
	}

	&__text {
		font-size: 16px;
	}

	&__location {
		display: block;
		font-size: 14px;
		color: #808080;
	}
}
