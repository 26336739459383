.destination-vido {
	position: relative;
	overflow: hidden;
	padding: vwm(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}

	&::after {
		content: '';
		background: url('../img/bg-destinations.png') no-repeat center;
		background-size: cover;
		display: none;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include minW(992) {
			display: block;
			width: vwd(461px);
			height: vwd(849px);
		}

		@include minW(1440) {
			width: 461px;
			height: 849px;
		}

	}

	&__title {
		text-align: center;
		font-size: vwm(32px);

		@include minW(640) {
			font-size: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(64px);
		}

		@include minW(1440) {
			font-size: 64px;
		}
	}

	&__body {
		@include minW(992) {
			width: 100%;
			max-width: 914px;
			padding-top: 64px;
			padding-left: 100px;
		}
	}

	&__action {
		text-align: center;
	}

	.taber {
		position: relative;
		z-index: 2;

		&__mobile {
			display: block;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #E6E6E6;
			border-bottom: 1px solid #E6E6E6;
			padding: vwm(16px);
			margin-bottom: vwm(32px);

			@include minW(640) {
				padding: vwt(32px);
			}

			@include minW(992) {
				display: none;
			}
		}

		&__current {
			color: $yellow-color;
			font-size: vwm(20px);
			display: block;

			@include minW(640) {
				font-size: vwt(40px);
			}
		}

		&__menu {
			display: none;

			@include minW(992) {
				display: flex;
				justify-content: center;
				border-top: 1px solid #E6E6E6;
				border-bottom: 1px solid #E6E6E6;
				padding: vwd(32px) 0;
			}

			@include minW(1440) {
				padding: 32px 0;
			}
		}

		&__link {
			margin: 0 45px;
			margin-bottom: 0;

			&::after {
				display: none;
			}
		}

		&__title {
			font-size: vwm(16px);
			text-align: center;
			margin-bottom: vwm(32px);

			@include minW(640) {
				font-size: vwt(32px);
				margin-bottom: vwt(64px);
			}

			@include minW(992) {
				font-size: vwd(24px);
				margin-bottom: vwd(32px);
			}

			@include minW(1440) {
				font-size: 24px;
				margin-bottom: 32px;
			}

		}
	}

	.slick {
		position: relative;
		margin-bottom: 32px;

		&::before {
			content: '';
			display: none;
			width: 2px;
			height: 100%;
			background: linear-gradient(90deg, #FBBC04 0%, rgba(251, 188, 4, 0.736) 26.4%, rgba(251, 188, 4, 0.502) 49.8%, rgba(251, 188, 4, 0.2792) 72.08%, rgba(251, 188, 4, 0.095) 90.5%, rgba(251, 188, 4, 0) 100%);
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			@include minW(992) {
				display: block;
			}
		}

		&__item {
			position: relative;

			&::before {
				content: '';
				display: none;
				width: 20px;
				height: 20px;
				background-color: #FACD49;
				border-radius: 50%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				@include minW(992) {
					display: block;
				}
			}

			&:nth-child(2n+2) {
				.card-reason {
					&__thumb {
						@include minW(992) {
							order: 1;
							justify-content: flex-start;
						}
					}
				}
			}
		}
	}
}