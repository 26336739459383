.radio {
	position: relative;
	margin-bottom: vwm(16px);
	user-select: none;

	@include minW(640) {
		margin-bottom: vwt(16px);
	}

	@include minW(992) {
		margin-bottom: vwd(16px);
	}

	@include minW(1440) {
		margin-bottom: 16px;
	}

	&__input {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:focus {
			+ span {
				&::before {
					border-color: #28303f;
				}
			}
		}

		&:checked {
			+ span {
				&::before {
					border-color: #fbbc04;
					background-color: #fbbc04;
				}
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&__text {
		position: relative;
		display: inline-block;
		align-items: center;
		min-height: vwm(20px);
		padding-left: vwm(30px);
		font-size: vwm(16px);
		cursor: pointer;
		color: #000;

		@include minW(640) {
			min-height: vwt(20px);
			padding-left: vwt(30px);
			font-size: vwt(32px);
		}

		@include minW(992) {
			min-height: vwd(20px);
			padding-left: vwd(30px);
			font-size: vwd(20px);
		}

		@include minW(1440) {
			min-height: 20px;
			padding-left: 30px;
			font-size: 20px;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			transition: all 0.3s ease;
		}

		&::before {
			border: 2px solid #28303f;
			background-color: #fff;
			left: 0;
			top: vwm(8px);
			width: vwm(16px);
			height: vwm(16px);
			border-radius: vwm(4px);

			@include minW(640) {
				top: vwt(8px);
				width: vwt(16px);
				height: vwt(16px);
				border-radius: vwt(4px);
			}

			@include minW(992) {
				top: vwd(8px);
				width: vwd(16px);
				height: vwd(16px);
				border-radius: vwd(4px);
			}

			@include minW(1440) {
				top: 8px;
				width: 16px;
				height: 16px;
				border-radius: 4px;
			}
		}

		&::after {
			content: '';
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
			transform: rotate(-45deg);
			opacity: 0;
			width: vwm(10px);
			height: vwm(5px);
			top: vwm(12px);
			left: vwm(4px);

			@include minW(640) {
				width: vwt(10px);
				height: vwt(5px);
				top: vwt(12px);
				left: vwt(4px);
			}

			@include minW(992) {
				width: vwd(10px);
				height: vwd(5px);
				top: vwd(12px);
				left: vwd(4px);
			}

			@include minW(1440) {
				width: 10px;
				height: 5px;
				top: 12px;
				left: 4px;
			}
		}
	}

	&__room {
		position: relative;
		display: block;
		color: #808080;
		font-size: vwm(16px);
		padding-left: vwm(32px);

		@include minW(640) {
			font-size: vwt(32px);
			padding-left: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			padding-left: vwd(32px);
		}

		@include minW(1440) {
			font-size: 16px;
			padding-left: 32px;
		}

		&::before {
			content: '';
			display: block;
			width: vwm(24px);
			height: vwm(24px);
			background: url('../img/icon-room.svg') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;

			@include minW(640) {
				width: vwt(48px);
				height: vwt(48px);
			}

			@include minW(992) {
				width: vwd(24px);
				height: vwd(24px);
			}

			@include minW(1440) {
				width: 24px;
				height: 24px;
			}
		}
	}

	&--button {
		.radio {
			&__text {
				padding: 12px 16px;
				border: 1px solid #d9d9d9;
				border-radius: 10px;

				&::before,
				&::after {
					display: none;
				}
			}

			&__input {
				position: absolute;
				opacity: 0;
				z-index: -1;

				&:focus {
					+ span {
						&::before {
							border-color: #28303f;
						}
					}
				}

				&:checked {
					+ span {
						background-color: #fbbc04;
					}
				}
			}
		}
	}
}
