.card-core {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	border: 1px solid transparent;
	padding: 16px;

	@include hover {
		border-color: #ccc;
		border-radius: 12px;
	}

	&__thumb {
		width: 100%;
		max-width: vwm(100px);
		margin-bottom: vwm(32px);

		@include minW(640) {
			margin-bottom: vwt(64px);
			max-width: vwt(200px);
		}

		@include minW(992) {
			margin-bottom: vwd(38px);
			max-width: vwd(100px);
		}

		@include minW(1440) {
			margin-bottom: 38px;
			max-width: 100px;
		}
	}

	&__title {
		font-size: vwm(24px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			font-size: vwt(48px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 8px;
		}
	}
}