.confirm-pay {
	position: relative;
	overflow: hidden;
	background: url('../img/bg-contact.png') no-repeat bottom center;
	background-size: cover;
	padding: vwm(64px) 0;

	@include minW(640) {
		padding: vwt(128px) 0;
	}

	@include minW(992) {
		padding: vwd(128px) 0;
	}

	@include minW(1440) {
		padding: 128px 0;
	}

	&__title {
		color: #fff;
		font-size: vwm(44px);
		margin-bottom: vwm(64px);
		text-align: center;

		@include minW(640) {
			font-size: vwt(44px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(44px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 44px;
			margin-bottom: 64px;
		}
	}

	&__body {
		background-color: #fff;
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(32px);
		}

		@include minW(1440) {
			padding: 32px;
		}
	}

	&__row {
		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -8px;
		}
	}

	&__col4 {
		@include minW(992) {
			width: 35%;
			flex-basis: 35%;
			padding: 0 8px;
		}
	}

	&__col8 {
		@include minW(992) {
			width: 65%;
			flex-basis: 65%;
			padding: 0 8px;
		}
	}
}