.what-nearby {
	position: relative;

	&__title {
		font-size: vwm(28px);

		@include minW(640) {
			font-size: vwt(28px);
		}

		@include minW(992) {
			font-size: vwd(28px);
		}

		@include minW(1440) {
			font-size: 28px;
		}
	}

	&__row {

		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vwd(-32px);
		}

		@include minW(1440) {
			margin: 0 -32px;
		}
	}

	&__col {
		@include minW(992) {
			width: 50%;
			flex-basis: 50%;
			padding: 0 vwd(32px);
		}

		@include minW(1440) {
			padding: 0 32px;
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
	}
}