.accordion {
	position: relative;

	&__item {
		&.is-show {
			> .accordion {
				&__body {
					display: block;
				}
				&__arrow {
					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		// &:first-child {
		// 	.accordion {
		// 		&__head {
		// 			border-top: 1px solid #e6e6e6;
		// 		}
		// 	}
		// }
	}

	&__head {
		position: relative;
		user-select: none;
		cursor: pointer;
		border-bottom: 1px solid #e6e6e6;
		padding: vwm(16px) vwm(8px) vwm(16px) 0;

		@include minW(640) {
			padding: vwt(32px) vwt(16px) vwt(32px) 0;
		}

		@include minW(992) {
			padding: vwd(16px) vwd(16px) vwd(16px) 0;
		}

		@include minW(1440) {
			padding: 16px 16px 16px 0;
		}
	}

	&__body {
		position: relative;
		overflow: hidden;
		display: none;
	}

	&__content {
		padding: 16px 8px 0;
	}

	&__title {
		font-size: vwm(24px);
		font-weight: 400;
		margin-bottom: 0;

		@include minW(640) {
			font-size: vwt(24px);
		}

		@include minW(992) {
			font-size: vwd(24px);
		}

		@include minW(1440) {
			font-size: 24px;
		}
	}

	&__arrow {
		position: absolute;
		width: 12px;
		height: 12px;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		border: 0;
		background-color: transparent;

		&::after {
			content: '\e911';
			display: block;
			font-family: $icon-font;
			speak: never;
			display: block;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}
