.card-overview {
	position: relative;
	border: 1px solid #D9D9D9;
	border-radius: vwm(12px);
	padding: vwm(16px);
	font-size: vwm(16px);

	@include minW(640) {
		border-radius: vwt(12px);
		padding: vwt(16px);
		font-size: vwt(16px);
	}

	@include minW(992) {
		border-radius: vwd(12px);
		padding: vwd(16px);
		font-size: vwd(16px);
	}

	@include minW(1440) {
		border-radius: 12px;
		padding: 16px;
		font-size: 16px;
	}

	&__title {
		font-weight: 400;
		font-size: vwm(20px);
		margin-bottom: vwm(8px);

		@include minW(640) {
			font-size: vwt(20px);
			margin-bottom: vwt(8px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 20px;
			margin-bottom: 8px;
		}
	}

	&__line {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__item {
		position: relative;
		padding-left: 28px;
		margin-bottom: 8px;

		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			background: url('../img/icon-checked-circle.svg') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__button {
		text-align: center;

		.btn {
			color: #808080;
			background-color: #fff;
			padding: 8px 16px;
		}
	}

	&--map {
		background: url('../img/card-overview-map.jpg') no-repeat center;
		background-size: cover;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
}