.contact {
	position: relative;
	background: url('../img/bg-contact.png') no-repeat center;
	background-size: cover;
	padding: 64px 0 130px;


	&__head {
		text-align: center;
		font-size: 20px;
		color: $white-color;
		margin-bottom: 64px;
	}

	&__title {
		font-size: vwm(54px);

		@include minW(640) {
			font-size: vwd(54px);
		}

		@include minW(992) {
			font-size: vwd(44px);
		}

		@include minW(1440) {
			font-size: 44px;
		}
	}

	&__desc {
		margin-bottom: 0;
		font-size: vwm(32px);

		@include minW(640) {
			font-size: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}
	}

	&__result {
		background-color: $white-color;
		width: 100%;
		padding: vwm(20px);

		@include minW(640) {
			padding: vwt(20px);
		}

		@include minW(992) {
			padding: vwd(32px);
		}

		@include minW(1440) {
			padding: 32px;
		}
	}

	&__message {
		background: url('../img/pic-contact.png') no-repeat center;
		background-size: cover;
		width: 100%;
		min-height: vwm(320px);
		padding: vwm(28px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $white-color;
		text-align: center;

		@include minW(640) {
			min-height: vwt(320px);
			padding: vwt(28px);
		}

		@include minW(992) {
			min-height: vwd(680px);
			padding: vwd(32px);
		}

		@include minW(1440) {
			min-height: 680px;
			padding: 32px;
		}
	}
}

.form-contact {
	background-color: $white-color;
	padding: 16px;

	@include minW(992) {
		display: flex;
	}

	&__info {

		background: url('../img/pic-contact-form.png') no-repeat center;
		background-size: cover;
		padding: 40px;
		color: $white-color;
		margin-bottom: 32px;

		@include minW(992) {
			width: 40%;
			flex-basis: 40%;
			padding: 40px;
			margin-bottom: 0;
		}
	}

	&__body {

		@include minW(992) {
			width: 60%;
			flex-basis: 60%;
			padding-left: 16px;
		}
	}
}