.datepicker {
	position: absolute;
	background-color: #fff;
	width: 100%;
	left: 0;
	display: none;
	top: calc(100% + vwm(8px));
	padding: vwm(32px) vwm(64px);
	z-index: 2;

	@include minW(640) {
		top: calc(100% + vwt(8px));
		padding: vwt(32px) vwt(64px);
	}

	@include minW(992) {
		top: calc(100% + vwd(8px));
		padding: vwd(32px) vwd(64px);
	}

	@include minW(1440) {
		top: calc(100% + 8px);
		padding: 32px 64px;
	}

	&__title {
		text-align: center;
		font-size: vwm(24px);
		margin-bottom: vwm(32px);

		@include minW(640) {
			font-size: vwt(24px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(32px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 32px;
		}
	}

	&--show {
		display: block;
	}
}

.ui-datepicker-header {
	text-align: center;
	position: relative;
	margin-bottom: vwm(16px);

	@include minW(640) {
		margin-bottom: vwt(32px);
	}

	@include minW(992) {
		margin-bottom: vwd(16px);
	}

	@include minW(1440) {
		margin-bottom: 16px;
	}
}

.ui-datepicker-inline {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;

	&.ui-datepicker-multi {
		flex-direction: row !important;
	}
}

.ui-datepicker-group {
	width: 50%;
	flex-basis: 50%;
}

.ui-datepicker-group-first {
	padding-right: vwm(104px);

	@include minW(640) {
		padding-right: vwm(104px);
	}

	@include minW(992) {
		padding-right: vwd(104px);
	}

	@include minW(1440) {
		padding-right: 104px;
	}
}

.ui-datepicker-group-last {
	padding-left: vwm(104px);

	@include minW(640) {
		padding-left: vwt(104px);
	}

	@include minW(992) {
		padding-left: vwd(104px);
	}

	@include minW(1440) {
		padding-left: 104px;
	}
}

.ui-state-default {
	color: #000;

	&.ui-state-active {
		background-color: $yellow-color;
		border-radius: 50%;
		color: $white-color;
	}
}

.ui-datepicker th {
	text-align: center;
	padding: 4px 8px;
	color: #858585;
	font-weight: 400;
}

.ui-datepicker thead tr,
.ui-datepicker tbody tr {
	display: flex;
}

.ui-datepicker th,
.ui-datepicker td {
	flex: 0 0 14.28%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.ui-datepicker td span, .ui-datepicker td a {
	text-align: center;
	width: vwm(34px);
	height: vwm(34px);
	line-height: 1.2;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	@include minW(640) {
		width: vwt(34px);
		height: vwt(34px);
	}

	@include minW(992) {
		width: vwd(34px);
		height: vwd(34px);
	}

	@include minW(1440) {
		width: 34px;
		height: 34px;
	}
}

.ui-datepicker-calendar {
	width: 100%;
}

.ui-state-disabled {
	cursor: default !important;
  pointer-events: none;

	span, a {
		color: #858585;
	}
}

.ui-datepicker-next,
.ui-datepicker-prev {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
	width: vwm(22px);
	height: vwm(22px);

	@include minW(640) {
		width: vwt(22px);
		height: vwt(22px);
	}

	@include minW(992) {
		width: vwd(22px);
		height: vwd(22px);
	}

	@include minW(1440) {
		width: 22px;
		height: 22px;
	}

	span {
		font-size: 0;
		display: block;
	}

	&.ui-state-disabled {
		span {
			border-color: #858585;
		}
	}
}

.ui-datepicker-prev {
	left: 0;

	span {
		border-radius: 2px;
		border-left: 2px solid #28303F;
		border-bottom: 2px solid #28303F;
		transform: rotate(45deg);
		width: vwm(8px);
		height: vwm(8px);

		@include minW(640) {
			width: vwt(8px);
			height: vwt(8px);
		}

		@include minW(992) {
			width: vwd(8px);
			height: vwd(8px);
		}

		@include minW(1440) {
			width: 8px;
			height: 8px;
		}
	}
}

.ui-datepicker-next {
	right: 0;

	span {
		border-radius: 2px;
		border-right: 2px solid #28303F;
		border-bottom: 2px solid #28303F;
		transform: rotate(-45deg);
		width: vwm(8px);
		height: vwm(8px);

		@include minW(640) {
			width: vwt(8px);
			height: vwt(8px);
		}

		@include minW(992) {
			width: vwd(8px);
			height: vwd(8px);
		}

		@include minW(1440) {
			width: 8px;
			height: 8px;
		}
	}
}

.ui-sold-out {
	pointer-events: none;
	cursor: default;

	a {
		border: 2px solid #C5C5C5;
		border-radius: 50%;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background-color: #C5C5C5;
			position: absolute;
			transform: rotate(45deg);
			z-index: 2;
		}
	}
}