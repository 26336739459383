.box-filter {
	background-color: rgba(#fff, 0.2);
	padding: vwm(32px);
	border-radius: vwm(12px);
	width: vwm(460px);

	@include minW(640) {
		padding: vwt(32px);
		border-radius: vwt(12px);
		width: vwt(920px);
	}

	@include minW(992) {
		padding: vwd(32px);
		border-radius: vwd(12px);
		width: vwd(1120px);
	}

	@include minW(1440) {
		padding: 32px;
		border-radius: 12px;
		width: 1120px;
	}

	&__body {
		background-color: #fff;
		position: relative;
		transition: background-color 0.3s ease-out;
	}

	&__form {
		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__label {
		display: block;
	}

	&__item {
		display: block;
		padding: vwm(24px) vwm(16px);
		transition: background-color 0.3s ease-out;
		margin-bottom: 0;

		@include minW(640) {
			padding: vwt(24px) vwt(16px);
		}

		@include minW(992) {
			width: 20%;
			flex-basis: 20%;
			padding: vwd(24px) vwd(16px);
		}

		@include minW(1440) {
			padding: 24px 16px;
		}

		&--bgwhite {
			background-color: #fff;
		}

		&--location,
		&--checkin,
		&--checkout {
			position: relative;

			@include minW(992) {
				position: static;
			}
		}

		&--who {
			position: relative;
		}

		.btn {
			width: 100%;
			justify-content: center;
		}

		input {
			border: 0;
			outline: 0;
			background-color: transparent;
			font-size: vwm(20px);
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
			width: 100%;

			@include minW(640) {
				font-size: vwt(20px);
			}

			@include minW(992) {
				font-size: vwd(20px);
			}

			@include minW(1440) {
				font-size: 20px;
			}
		}
	}

	&__label {
		font-weight: 700;
		color: #000;
		font-size: vwm(16px);
		margin-bottom: vwm(8px);

		@include minW(640) {
			font-size: vwt(16px);
			margin-bottom: vwt(8px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 16px;
			margin-bottom: 8px;
		}
	}

	&__text {
		display: block;
		color: #808080;
		font-size: vwm(20px);
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;

		@include minW(640) {
			font-size: vwt(20px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}

		&--value {
			color: #292929;
		}
	}

	&--show {
		.box-filter {
			&__body {
				background-color: #d9d9d9;
			}
		}
	}
}

.box-qty {
	position: absolute;
	width: 100%;
	left: 0;
	background-color: #fff;
	display: none;
	top: calc(100% + vwm(8px));
	box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
	padding: vwm(16px);
	z-index: 2;

	@include minW(640) {
		top: calc(100% + vwt(16px));
		padding: vwt(32px);
	}

	@include minW(992) {
		left: 50%;
		transform: translateX(-50%);
		width: vwd(428px);
		top: calc(100% + vwd(8px));
		padding: vwd(32px);
	}

	@include minW(1440) {
		width: 428px;
		top: calc(100% + 8px);
		padding: 32px;
	}

	&__item {
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: vwm(12px);
		margin-bottom: vwm(12px);

		@include minW(640) {
			padding-bottom: vwt(24px);
			margin-bottom: vwt(24px);
		}

		@include minW(992) {
			padding-bottom: vwd(24px);
			margin-bottom: vwd(24px);
		}

		@include minW(1440) {
			padding-bottom: 24px;
			margin-bottom: 24px;
		}

		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 -8px;
	}

	&__col {
		width: 50%;
		flex-basis: 50%;
		padding: 0 8px;
	}

	&__title {
		font-weight: 400;
		font-size: vwm(20px);
		margin-bottom: vwm(8px);

		@include minW(640) {
			font-size: vwt(20px);
			margin-bottom: vwt(8px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 20px;
			margin-bottom: 8px;
		}
	}

	&__note {
		color: #808080;
		margin-bottom: 0;
		font-size: vwm(16px);

		@include minW(640) {
			font-size: vwt(16px);
		}

		@include minW(992) {
			font-size: vwd(16px);
		}

		@include minW(1440) {
			font-size: 16px;
		}
	}

	&--show {
		display: block;
	}
}

.qty-vido {
	position: relative;
	display: flex;
	justify-content: flex-end;

	&__input {
		width: 32px !important;
		height: 32px !important;
		border: 0;
		outline: 0;
		background-color: transparent;
		text-align: center !important;
	}

	&__btn {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		border: 1px solid #808080;
		background-color: transparent;
		font-size: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: 0;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 2px;
			background-color: #808080;
			position: absolute;
		}

		&--plus {
			&::after {
				transform: rotate(90deg);
			}
		}

		&--disabled {
			cursor: default;
			pointer-events: none;
			border-color: #e6e6e6;

			&::before,
			&::after {
				background-color: #e6e6e6;
			}
		}

		&:hover,
		&:active {
			outline: 0;
		}
	}
}

.ui-autocomplete {
	position: absolute;
	left: 0 !important;
	background-color: #fff;
	list-style: none;
	overflow: hidden;
	overflow-y: auto;
	top: calc(100% + vwm(8px)) !important;
	padding: vwm(32px);
	width: 100% !important;
	max-height: vwm(280px);
	box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
	z-index: 2;

	@include minW(640) {
		top: calc(100% + vwt(8px)) !important;
		padding: vwt(32px);
		max-height: vwt(280px);
	}

	@include minW(992) {
		top: calc(100% + vwd(8px)) !important;
		padding: vwd(32px);
		width: vwd(428px) !important;
		max-height: vwd(280px);
	}

	@include minW(1440) {
		top: calc(100% + 8px) !important;
		padding: 32px;
		width: 428px !important;
		max-height: 280px;
	}
}

.ui-menu {
	.ui-menu-item {
		position: relative;
		cursor: pointer;
		font-size: vwm(20px);
		padding-left: vwm(32px);
		margin-bottom: vwm(32px);

		@include minW(640) {
			font-size: vwt(20px);
			padding-left: vwt(32px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			padding-left: vwd(32px);
			margin-bottom: vwd(32px);
		}

		@include minW(1440) {
			font-size: 20px;
			padding-left: 32px;
			margin-bottom: 32px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: '';
			display: block;
			background: url('../img/icon-search.svg') no-repeat center;
			background-size: cover;
			position: absolute;
			left: 0;
			width: vwm(24px);
			height: vwm(24px);
			top: vwm(2px);

			@include minW(640) {
				width: vwt(24px);
				height: vwt(24px);
				top: vwt(2px);
			}

			@include minW(992) {
				width: vwd(24px);
				height: vwd(24px);
				top: vwd(2px);
			}

			@include minW(1440) {
				width: 24px;
				height: 24px;
				top: 2px;
			}
		}
	}
}
