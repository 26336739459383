.filter-detail {
	border: 1px solid #E1D1B2;
	padding: vwm(16px);
	border-radius: vwm(10px);

	@include minW(640) {
		padding: vwt(32px);
		border-radius: vwt(20px);
	}

	@include minW(992) {
		padding: vwd(16px);
		border-radius: vwd(10px);
	}

	@include minW(1440) {
		padding: 32px;
		border-radius: 10px;
	}

	&__price {
		text-align: center;
		font-size: vwm(32px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(16px);
		}

		@include minW(992) {
			font-size: vwd(32px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			font-size: 32px;
			margin-bottom: 16px;
		}
	}

	&__row {
		@include minW(992) {
			position: relative;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__col {
		position: relative;

		@include minW(992) {
			position: static;
			width: 50%;
			flex-basis: 50%;
		}

		&:first-child {
			.filter-detail {
				&__item {

					@include minW(992) {
						border-right: 0;
					}
				}
			}
		}
	}

	&__item {
		border: 1px solid #D9D9D9;
		padding: vwm(12px) vwm(32px);
		margin-bottom: vwm(16px);
		cursor: pointer;

		@include minW(640) {
			padding: vwt(24px) vwt(64px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(8px) vwd(32px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			padding: 8px 32px;
			margin-bottom: 16px;
		}

		&--who,
		&--room {
			position: relative;

			.box-qty {
				left: 0;
				transform: none;
				right: auto;
				width: 100%;
				box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
				z-index: 2;

				@include minW(640) {

				}

				@include minW(992) {
					left: auto;
					right: vwd(-34px);
					width: vwd(428px);
				}

				@include minW(1440) {
					right: -34px;
					width: 428px;
				}
			}
		}
	}

	&__label {
		font-size: vwm(14px);
		display: block;

		@include minW(640) {
			font-size: vwt(28px);
		}

		@include minW(992) {
			font-size: vwd(16px);
		}

		@include minW(1440) {
			font-size: 16px;
		}
	}

	&__text {
		font-size: vwm(16px);
		color: #808080;

		@include minW(640) {
			font-size: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(18px);
		}

		@include minW(1440) {
			font-size: 18px;
		}

		&--value {
			color: #000;
		}
	}

	&__date {
		position: absolute;
		right: 0;
		background-color: #fff;
		width: 100%;
		top: calc(100% + vwm(8px));
		padding: vwt(32px);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
		display: none;
		z-index: 2;

		@include minW(640) {
			top: calc(100% + vwm(16px));
			padding: vwt(64px);
		}

		@include minW(992) {
			width: vwd(1000px);
			top: calc(100% - vwd(8px));
			padding: vwd(32px) vwd(64px);
			right: vwd(-33px);
		}

		@include minW(1440) {
			width: 1000px;
			top: calc(100% - 8px);
			padding: 32px 64px;
			right: -33px;
		}

		&--show {
			display: block;
		}
	}

	&__title {
		text-align: center;
		font-size: vwm(20px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			font-size: vwt(40px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(32px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 32px;
		}
	}

	&--show {
		background-color: #D9D9D9;

		.filter-detail {
			&__item {
				border-color: #fff;

				&--bgwhite {
					background-color: #fff;
				}
			}
		}
	}
}