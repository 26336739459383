.filter-vido {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-width: 320px;
	transform: translateX(-100%);
	opacity: 0;
	visibility: hidden;
	background-color: #fff;
	transition: all 0.3s ease-out;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 99;

	&__main {
		padding: 32px;
	}

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;

		.close {
			font-size: 24px;
			color: #808080;
			outline: 0;
		}
	}

	&__title {
		font-size: 28px;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	&--show {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}

	.slider.slider-horizontal {
		position: relative;
	}

	.slider-selection.tick-slider-selection {
		background-color: #FBBC04;
	}

	.slider-handle {
		background-color: #F5F5F5;
		border-color: #FBBC04;
	}

	.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
		font-size: 12px;
		top: -20px;
		position: absolute;


		&:first-child {
			left: 0;
			text-align: left;
		}

		&:last-child {
			right: 0;
			text-align: right;
		}
	}
}