.transaction-history {
	position: relative;
	overflow: hidden;
	padding: vwt(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}

	&__head {
		position: relative;
		padding: vwm(16px) vwm(32px);

		@include minW(640) {
			padding: vwt(16px) vwt(64px);
		}

		@include minW(992) {
			padding: vwd(16px) vwd(64px);
		}

		@include minW(1440) {
			padding: 16px 64px;
		}

		.select {
			width: 200px;
		}
	}

	&__foot {
		padding-top: 128px;
		text-align: center;
	}

	&__nodata {
		text-align: center;
	}

	.row {
		margin: 0;

		&__col {
			padding: 0 4px;
		}
	}
}
