.best-vido {
	position: relative;
	overflow: hidden;
	padding: vwm(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}

	&__head {
		text-align: center;
		font-size: vwm(16px);
		margin-bottom: vwm(32px);
		color: #808080;

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(28px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 28px;
			margin-bottom: 64px;
		}

	}

	&__title {
		text-transform: uppercase;
		margin-bottom: 0;
		color: #000;
		font-size: vwm(32px);

		@include minW(640) {
			font-size: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(64px);
		}

		@include minW(1440) {
			font-size: 64px;
		}
	}

	&__body {
		@include minW(992) {
			padding: 0 vwd(16px);
		}

		@include minW(1440) {
			padding: 0 16px;
		}
	}
}