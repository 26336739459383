.navcontact {
	position: relative;

	&__body {
		border-bottom: 1px dashed $black-color;
		padding-bottom: 16px;
		margin-bottom: 32px;
	}

	&__title {
		font-size: vwm(36px);
		margin-bottom: vwm(48px);

		@include minW(992) {
			font-size: vwt(36px);
			margin-bottom: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(36px);
			margin-bottom: vwd(48px);
		}

		@include minW(1440) {
			font-size: 36px;
			margin-bottom: 48px;
		}
	}

	&__office,
	&__doyou {
		font-size: 32px;
	}

	&--show {
		.navcontact {
			&__dialog {
				transform: none;
			}
		}
	}

	.btn {
		font-size: 32px;
	}

	a {
		color: $black-color;
		transition: color 0.3s ease-out;

		@include hover {
			color: $yellow-color;
		}
	}
}