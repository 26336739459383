.only-vido {
	position: relative;
	overflow: hidden;

	@include minW(992) {
		display: flex;
		margin-bottom: 128px;
	}

	&__thumb {
		@include minW(992) {
			width: 40%;
			flex-basis: 40%;
		}
	}

	&__body {
		padding: 32px;
		background-color: #292929;
		color: #fff;

		@include minW(992) {
			width: 60%;
			flex-basis: 60%;
			padding: 64px;
		}
	}
}