.card-confirm {
	position: relative;
	background-color: #F5F5F5;
	padding: vwm(32px) vwm(16px);
	margin-bottom: vwm(16px);
	border-radius: vwm(12px);
	font-size: vwm(20px);

	@include minW(640) {
		padding: vwt(32px) vwt(16px);
		margin-bottom: vwt(16px);
		border-radius: vwt(12px);
		font-size: vwt(20px);
	}

	@include minW(992) {
		padding: vwd(32px) vwd(16px);
		margin-bottom: vwd(16px);
		border-radius: vwd(12px);
		font-size: vwd(20px);
	}

	@include minW(1440) {
		padding: 32px 16px;
		margin-bottom: 16px;
		border-radius: 12px;
		font-size: 20px;
	}

	&__title {
		font-size: vwm(24px);
		margin-bottom: vwm(8px);

		@include minW(640) {
			font-size: vwt(24px);
			margin-bottom: vwt(16px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 8px;
		}
	}

	&__desc {
		color: #808080;
	}

	&__button {
		text-align: right;
	}

	&__group {
		margin-bottom: 16px;

		@include minW(992) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.card-confirm {
			&__title {

				@include minW(992) {
					margin-bottom: 0 !important;
				}
			}
		}
	}

	&__line {
		display: flex;
		justify-content: space-between;

		&--total {
			color: #8E1022;
		}
	}

	&__method {
		display: flex;
		align-items: center;

		img {
			margin-left: 8px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.form {
		&__control {
			border: 1px solid #808080;
			border-radius: 10px;
			padding: 16px;
		}

		&__group {
			.row {
				margin: 0 -4px;

				&__col {
					padding: 0 4px;
				}
			}
		}
	}

	&--location {
		p {
			display: flex;
			color: #191825;
			font-size: 18px;

			.icon {
				color: #808080;
				font-size: 20px;
				margin-right: 8px;
				margin-top: 4px;
			}

			&.card-confirm__note {
				color: #808080;
			}
		}
	}

	&--cancel {
		.card-confirm {
			&__desc {
				margin-bottom: 0;
			}
		}
	}

	&--policy {
		a {
			color: #000;
			text-decoration: underline;
		}
	}

	+ .card-confirm {
		margin-top: 16px;
	}
}

.method {
	display: none;

	&--credit-card {
		display: block;
	}
}