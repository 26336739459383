.account-settings {
	position: relative;
	padding: 128px 0;

	&__button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 32px;
	}
}
