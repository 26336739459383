.carousel-vido {
	position: relative;

	@include minW(992) {
		margin-bottom: vwd(32px);
	}

	@include minW(1440) {
		margin-bottom: 32px;
	}

	&__content {
		text-align: center;
		padding: vwm(32px);

		@include minW(640) {
			padding: vwt(64px);
		}

		@include minW(992) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			color: #fff;
			z-index: 2;
			padding: vwd(32px);
		}

		@include minW(992) {
			padding: 32px;
		}
	}

	&__city {
		font-size: vwm(16px);
		margin-bottom: vwm(16px);

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 16px;
		}
	}

	&__hotel {
		font-size: vwm(20px);
		margin-bottom: 0;

		@include minW(640) {
			font-size: vwt(40px);
		}

		@include minW(992) {
			font-size: vwd(40px);
		}

		@include minW(1440) {
			font-size: 40px;
		}
	}

	.slick {
		&-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 3;
			font-size: 0;
			outline: 0;
			background-color: transparent;
			border: 0;
			width: vwm(50px);
			height: vwm(50px);
			display: flex;
			align-items: center;
			justify-content: center;

			@include minW(640) {
				width: vwt(100px);
				height: vwt(100px);
			}

			@include minW(992) {
				width: vwd(100px);
				height: vwd(100px);
			}

			@include minW(1440) {
				width: 100px;
				height: 100px;
			}

			&::before {
				content: '';
				display: block;
				width: vwm(25px);
				height: vwm(25px);
				border-left: 3px solid #fff;
				border-bottom: 3px solid #fff;
				position: absolute;

				@include minW(640) {
					width: vwt(50px);
					height: vwt(50px);
				}

				@include minW(992) {
					width: vwd(50px);
					height: vwd(50px);
				}

				@include minW(1440) {
					width: 50px;
					height: 50px;
				}
			}
		}

		&-prev {
			left: vwm(50px);

			@include minW(640) {
				left: vwt(100px);
			}

			@include minW(992) {
				left: vwd(100px);
			}

			@include minW(1440) {
				left: 100px;
			}

			&::before {
				transform: rotate(45deg);
				margin-left: vwm(10px);

				@include minW(640) {
					margin-left: vwt(20px);
				}

				@include minW(992) {
					margin-left: vwd(20px);
				}

				@include minW(1440) {
					margin-left: 20px;
				}
			}
		}

		&-next {
			right: vwm(50px);

			@include minW(640) {
				right: vwt(100px);
			}

			@include minW(992) {
				right: vwd(100px);
			}

			@include minW(1440) {
				right: 100px;
			}

			&::before {
				transform: rotate(225deg);
				margin-right: vwm(10px);

				@include minW(640) {
					margin-right: vwt(20px);
				}

				@include minW(992) {
					margin-right: vwd(20px);
				}

				@include minW(1440) {
					margin-right: 20px;
				}
			}
		}
	}
}