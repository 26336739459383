.core-vido {
	background-color: #FAFAFA;
	overflow: hidden;
	padding: vwm(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}

	&__body {
		margin: 0 vwm(-16px);

		@include minW(992) {
			margin: 0;
		}
	}

	.slick {
		&__item {
			@include minW(992) {
				padding: 16px;
			}
		}
	}
}