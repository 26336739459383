.collapse {
  position: relative;
  margin-bottom: 16px;

  &__item {
    margin-bottom: 16px;

    @include minW(992) {
      margin-bottom: 32px;
    }

    &.is-show {
      .collapse {
        &__arrow {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__head {
    position: relative;
    padding: 16px 32px 16px 16px;
    user-select: none;
    cursor: pointer;
    border-bottom: 1px solid $black-color;
  }

  &__body {
    position: relative;
    overflow: hidden;
    height: 0;
  }

  &__content {
    padding: 16px 8px 0;
  }

  &__title {
    font-family: $primary-font;
    font-size: vwm(31px);
    font-weight: 400;
    margin-bottom: 0;

    @include minW(640) {
      font-size: vwt(36px);
    }

    @include minW(992) {
      font-size: vwd(36px);
    }

    @include minW(1440) {
      font-size: 36px;
    }
  }

  &__arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background-color: transparent;

    &::after {
      content: "\e901";
      display: block;
      font-family: $icon-font;
      speak: never;
      display: block;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
