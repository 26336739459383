.whats-included {
	list-style: none;
	padding: 0;
	margin: 0;

	&__item {
		position: relative;
		padding-left: 32px;
		margin-bottom: 16px;

		.icon-svg {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
