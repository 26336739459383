.form {
	&__group {
		margin-bottom: 16px;
	}

	&__label {
		font-size: 12px;
		color: #757575;
	}

	&__control {
		border: 0;
		border-bottom: 1px solid $black-color;
		width: 100%;
		padding: 8px 0;
		outline: 0;
		background-color: transparent;

		&:focus {
			outline: 0;
		}
	}

	&__button {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include minW(992) {
			align-items: flex-end;
		}

		img {
			margin-right: 100px;
		}
	}

	.g-recaptcha {
		transform: scale(0.77);
		transform-origin: 0 0;

		@media (min-width: 640px) {
			transform: scale(1);
		}
	}
}