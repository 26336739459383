.hotel-facility {
	position: relative;

	&__title {
		font-size: vwm(28px);

		@include minW(640) {
			font-size: vwt(28px);
		}

		@include minW(992) {
			font-size: vwd(28px);
		}

		@include minW(1440) {
			font-size: 28px;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
	}

	&__col {
		width: 50%;
		flex-basis: 50%;

		@include minW(992) {
			width: 33.33333%;
			flex-basis: 33.33333%;
		}
	}

	&__item {
		display: flex;
		margin-bottom: vwm(16px);

		@include minW(640) {
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			margin-bottom: 16px;
		}

		.icon {
			color: $yellow-color;
			font-size: vwm(20px);
			margin-right: vwm(8px);

			@include minW(640) {
				font-size: vwt(20px);
				margin-right: vwt(8px);
			}

			@include minW(992) {
				font-size: vwd(20px);
				margin-right: vwd(8px);
			}

			@include minW(1440) {
				font-size: 20px;
				margin-right: 8px;
			}
		}
	}
}