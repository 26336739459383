.dropdown {
	position: relative;

  &__choose {
    position: relative;
    font-size: vwm(14px);
    display: flex;
    align-items: center;

		@include minW(640) {
			font-size: vwt(28px);
		}
  }

  &__arrow {
    margin-left: 8px;

    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-left: 1px solid #808080;
      border-bottom: 1px solid #808080;
      transform: rotate(-45deg);
      margin-top: -3px;
    }
  }

	&__list {
		position: absolute;
    top: vwm(42px);
    background-color: #fff;
    list-style: none;
    width: 100%;
    padding: vwm(16px);
    margin-bottom: 0;
    display: none;
    box-shadow: 0 0 5px rgba(#808080, 0.5);
    z-index: 9;

    @include minW(640) {
      top: vwt(84px);
      padding: vwt(32px);
    }
	}

  &__item {
    padding: 4px 0;
  }

  &--show {
    .dropdown {
      &__list {
        display: block;
      }
    }
  }
}