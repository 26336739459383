.hotel-vido {
	position: relative;
	width: 100%;
	padding: 0 vwm(16px);
	font-size: vw(16px);

	@include minW(640) {
		padding: 0 vwt(64px);
		font-size: vwt(32px);
	}

	@include minW(992) {
		padding: 0 vwd(64px);
		font-size: vwd(20px);
	}

	@include minW(1440) {
		padding: 0 64px;
		font-size: 20px;
	}

	&__title {
		position: relative;
		font-size: 28px;
		padding-left: 32px;

		&::before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			background-color: #C5C5C5;
			border-radius: 4px;
			position: absolute;
			left: 0;
			top: 8px;
		}
	}

	&__head {
		margin-bottom: vwm(32px);

		@include minW(640) {
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			margin-bottom: 64px;
		}
	}

	&__content {
		display: none;

		@include minW(992) {
			display: block;
		}
	}

	&__box {
		margin-top: 32px;

		.row {
			margin: 0 -8px;

			&__col {
				padding: 0 8px;
			}
		}

		.card-overview {
			height: 100%;
		}
	}
}