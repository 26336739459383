.price-vido {
	margin-bottom: 32px;

	&__name {
		font-size: 24px;
		margin-bottom: 32px;
	}

	&__wrap {
		position: relative;
		padding-top: 24px;
	}

	&__label {
		position: absolute;
		top: 0;
		font-size: 14px;

		&--min {
			left: 0;
		}

		&--max {
			right: 0;
		}
	}
}
