.cart {
	position: relative;
	overflow: hidden;
	background: url('../img/bg-contact.png') no-repeat bottom center;
	background-size: cover;
	padding: vwm(64px) vwm(32px);

	@include minW(640) {
		padding: vwt(128px) vwt(64px);
	}

	@include minW(992) {
		padding: vwd(128px) vwd(64px);
	}

	@include minW(1440) {
		padding: 128px 64px;
	}

	&__title {
		color: #fff;
		font-size: vwm(44px);
		margin-bottom: vwm(64px);
		text-align: center;

		@include minW(640) {
			font-size: vwt(44px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(44px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 44px;
			margin-bottom: 64px;
		}
	}

	&__body {
		background-color: #fff;
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(32px);
		}

		@include minW(1440) {
			padding: 32px;
		}
	}

	&__subtitle {
		font-size: vwm(24px);

		@include minW(640) {
			font-size: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(40px);
		}

		@include minW(1440) {
			font-size: 40px;
		}
	}

	&__nodata {
		text-align: center;
	}
}
