.my-account {
	position: relative;
	padding: 128px 0;

	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -64px;
	}

	&__col {
		width: 50%;
		flex-basis: 50%;
		padding: 0 64px;
		margin-bottom: 128px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.card-my-account {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 24px;

	&__icon {
		margin-bottom: 16px;
	}

	&__title {
		font-size: 36px;
		font-weight: 400;
		margin-bottom: 16px;
	}
}
