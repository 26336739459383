.top-vido {
	position: relative;
	padding: vwm(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}


	&__head {
		text-align: center;
		font-size: vwm(16px);
		margin-bottom: vwm(32px);
		color: #808080;

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 64px;
		}
	}

	&__title {
		color: #000;
		margin-bottom: 0;
		font-size: vwm(32px);

		@include minW(640) {
			font-size: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(64px);
		}

		@include minW(1440) {
			font-size: 64px;
		}
	}

	&__desc {
		margin-bottom: 0;
	}

	&__body {
		margin-bottom: 32px;
	}

	&__foot {
		text-align: center;
	}

	.slick {
		&-slide {
			.card-top {
				&__body {
					@include minW(992) {
						opacity: 0;
					}
				}
			}
		}

		&-center {
			.card-top {
				@include minW(992) {
					transform: scale(1.08);
				}

				&__body {
					@include minW(992) {
						opacity: 1;
					}
				}
			}
		}

		&-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			width: vwm(60px);
			height: vwm(60px);
			border-radius: 50%;
			background-color: #FAFAFA;
			outline: 0;
			font-size: 0;
			border: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			@include minW(640) {
				width: vwt(60px);
				height: vwt(60px);
			}

			@include minW(992) {
				width: vwd(60px);
				height: vwd(60px);
			}

			@include minW(1440) {
				width: 60px;
				height: 60px;
			}

			&::before {
				content: '';
				display: block;
				width: vwm(16px);
				height: vwm(16px);
				border-left: 2px solid #292929;
				border-bottom: 2px solid #292929;

				@include minW(640) {
					width: vwt(16px);
					height: vwt(16px);
				}

				@include minW(992) {
					width: vwd(16px);
					height: vwd(16px);
				}

				@include minW(1440) {
					width: 16px;
					height: 16px;
				}
			}
		}

		&-prev {
			left: 5%;

			&::before {
				transform: rotate(45deg);
				margin-left: 6px;
			}
		}

		&-next {
			right: 5%;

			&::before {
				transform: rotate(-135deg);
				margin-left: -6px;
			}
		}
	}
}