.background {
	position: relative;
	width: 100%;
	height: 100vh;

	&__image {
		position: relative;
		width: 100%;
		height: 100%;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: rgba($black-color, 0.3);
			z-index: 2;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__body {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		z-index: 2;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: vwm(64px) vwm(32px);

		@include minW(640) {
			padding: vwt(80px) vwt(48px);
		}

		@include minW(992) {
			padding: vwd(64px);
		}

		@include minW(1440) {
			padding: 64px;
		}
	}

	&__cate {
		font-size: vwm(24px);
		letter-spacing: 5px;
		color: $yellow-color;
		text-transform: uppercase;
		margin-bottom: 0;

		@include minW(640) {
			font-size: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
		}

		@include minW(1440) {
			font-size: 24px;
		}
	}

	&__title {
		font-size: vwm(36px);
		color: $white-color;
		margin: vwm(48px) 0;

		@include minW(640) {
			font-size: vwt(48px);
			margin: vwt(64px) 0;
		}

		@include minW(992) {
			font-size: vwd(64px);
			margin: vwd(32px) 0;
		}

		@include minW(1440) {
			font-size: 64px;
			margin: 32px 0;
		}

		&--uppercase {
			text-transform: uppercase;
		}
	}

	&--center {
		.background {
			&__body {
				align-items: center;
			}
		}
	}
}