.select {
	margin-bottom: 16px;
}

.select2-container--default .select2-selection--single {
	background-color: transparent;
	border: 1px solid #808080;
	padding: 16px 60px 16px 16px;
	height: 60px;
	border-radius: 10px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 60px;
	width: 60px;
}