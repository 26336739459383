.icon {
	font-family: $icon-font;
	speak: never;
	display: block;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&--cart-shopping:before {
		content: '\e903';
	}

	&--bed:before {
		content: '\e900';
	}
	&--checked-circle:before {
		content: '\e901';
	}
	&--close-circle:before {
		content: '\e902';
	}
	&--email:before {
		content: '\e904';
	}
	&--home-location:before {
		content: '\e905';
	}
	&--location:before {
		content: '\e906';
	}
	&--phone:before {
		content: '\e908';
	}
	&--sold-out:before {
		content: '\e909';
	}
	&--calendar-check:before {
		content: '\e90b';
	}
	&--calendar-days:before {
		content: '\e90c';
	}
	&--chevron-left:before {
		content: '\e90d';
	}
	&--chevron-right:before {
		content: '\e90e';
	}
	&--chevron-up:before {
		content: '\e90f';
	}
	&--bars:before {
		content: '\e910';
	}
	&--chevron-down:before {
		content: '\e911';
	}
	&--facebook:before {
		content: '\e912';
	}
	&--instagram:before {
		content: '\e913';
	}
	&--plus:before {
		content: '\e914';
	}
	&--search:before {
		content: '\e915';
	}
	&--twitter:before {
		content: '\e916';
	}
	&--user:before {
		content: '\e917';
	}
	&--xmark:before {
		content: '\e918';
	}
	&--youtube:before {
		content: '\e919';
	}
	&--location-dot:before {
		content: '\e91a';
	}
	&--phone-volume:before {
		content: '\e91b';
	}
}

.icon-svg {
	display: inline-block;
	width: vwm(20px);
	height: vwm(20px);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@include minW(640) {
		width: vwt(40px);
		height: vwt(40px);
	}

	@include minW(992) {
		width: vwd(24px);
		height: vwd(24px);
	}

	@include minW(1440) {
		width: 24px;
		height: 24px;
	}

	&--sold-out {
		background-image: url('../img/icon-sold-out.svg');
	}

	&--chat {
		background-image: url('../img/icon-chat.svg');
	}

	&--location {
		background-image: url('../img/icon-location-map.svg');
	}

	&--flag {
		background-image: url('../img/icon-location-flag.svg');
	}

	&--info {
		background-image: url('../img/icon-info.svg');
	}

	&--checked-circle {
		background-image: url('../img/icon-checked-circle.svg');
	}

	&--checked-square {
		background-image: url('../img/icon-checked-square.svg');
	}

	&--room {
		background-image: url('../img/icon-room.svg');
	}
}
