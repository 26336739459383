.result-vido {
	position: relative;
	overflow: hidden;
	padding: vwt(32px) 0;

	@include minW(640) {
		padding: vwt(64px) 0;
	}

	@include minW(992) {
		padding: vwd(64px) 0;
	}

	@include minW(1440) {
		padding: 64px 0;
	}

	&__top {
		display: flex;
		justify-content: center;
		margin-bottom: vwm(64px);
		position: relative;
		z-index: 2;

		@include minW(640) {
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			margin-bottom: 64px;
		}

		.filter-tour,
		.box-filter {
			padding: 0;
			background: #FFFFFF;
			box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
		}
	}

	&__head {
		position: relative;
		text-align: center;
		padding: vwm(16px) vwm(32px);

		@include minW(640) {
			padding: vwt(16px) vwt(64px);
		}

		@include minW(992) {
			padding: vwd(16px) vwd(64px);
		}

		@include minW(1440) {
			padding: 16px 64px;
		}
	}

	&__title {
		font-size: vwm(20px);
		margin-bottom: vwm(16px);
		font-weight: 400;

		@include minW(640) {
			font-size: vwt(40px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: 0;
		}

		@include minW(1440) {
			font-size: 24px;
		}

		strong {
			font-weight: 700;
		}
	}

	&__total {
		font-size: vwm(20px);
		color: #808080;

		@include minW(640) {
			font-size: vwt(20px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			position: absolute;
			right: vwd(64px);
			top: 50%;
			transform: translateY(-50%);
		}

		@include minW(1440) {
			font-size: 20px;
			right: 64px;
		}
	}

	&__action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: vwm(16px) vwm(32px);

		@include minW(640) {
			padding: vwt(16px) vwt(64px);
		}

		@include minW(992) {
			padding: vwd(16px) vwd(64px);
		}

		@include minW(1440) {
			padding: 16px 64px;
		}
	}

	&__filter {
		background-color: transparent;
		border: 0;
		font-size: vwm(16px);
		color: #292929;
		display: flex;
		align-items: center;
		outline: 0;

		@include minW(640) {
			font-size: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}

		&::before {
			content: '';
			display: block;
			background: url('../img/icon-filter.svg') no-repeat center;
			background-size: cover;
			width: vwm(20px);
			height: vwm(14px);
			margin-right: vwm(8px);

			@include minW(640) {
				width: vwt(40px);
				height: vwt(28px);
				margin-right: vwt(16px);
			}

			@include minW(992) {
				width: vwd(20px);
				height: vwd(14px);
				margin-right: vwd(8px);
			}

			@include minW(1440) {
				width: 20px;
				height: 14px;
				margin-right: 8px;
			}
		}

		&:focus {
			outline: 0;
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		padding: 0 4px;
		margin-bottom: vwm(32px);

		@include minW(640) {
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			margin-bottom: 64px;
		}
	}

	&__item {
		width: 50%;
		flex-basis: 50%;
		padding: 0 4px;

		@include minW(992) {
			width: 33.3333%;
			flex-basis: 33.33333%;
			padding: 0 4px;
		}
	}

	&__foot {
		text-align: center;
	}
}