.banner-top {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: cover;
	display: flex;
	align-items: center;
	padding: vwm(200px) 0;
	z-index: 2;

	@include minW(640) {
		padding: vwt(400px) 0;
	}

	@include minW(992) {
		padding: vwd(200px) 0;
	}

	@include minW(1440) {
		padding: 200px 0;
	}

	&__body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__content {
		color: #fff;
		text-align: center;
		font-size: vwm(16px);
		margin-bottom: vwm(64px);

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 64px;
		}
	}

	&__title {
		font-size: vwm(32px);
		line-height: 1.4;
		margin-bottom: 0;

		@include minW(640) {
			font-size: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(72px);
		}

		@include minW(1440) {
			font-size: 72px;
		}
	}
}