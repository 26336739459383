.collapse-vido {
	position: relative;

	&__title {
		position: relative;
		font-size: vwm(20px);
		padding-left: vwm(32px);

		@include minW(640) {
			font-size: vwt(40px);
			padding-left: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(28px);
			padding-left: vwd(32px);
		}

		@include minW(1440) {
			font-size: 28px;
			padding-left: 32px;
		}

		&::before {
			content: '';
			display: block;
			background-color: #C5C5C5;
			border-radius: 4px;
			position: absolute;
			left: 0;
			top: vwm(4px);
			width: vwm(20px);
			height: vwm(20px);

			@include minW(640) {
				top: vwt(8px);
				width: vwt(40px);
				height: vwt(40px);
			}

			@include minW(992) {
				top: vwd(8px);
				width: vwd(24px);
				height: vwd(24px);
			}

			@include minW(1440) {
				top: 8px;
				width: 24px;
				height: 24px;
			}
		}
	}

	&__item {
		border-bottom: 1px solid #E1D1B2;
		margin-bottom: 16px;

		@include minW(992) {
			margin-bottom: 64px;
			border-bottom: 0;
		}

		&--mb {
			@include minW(992) {
				display: none;
			}
		}
	}

	&__body {
		height: 0;
		overflow: hidden;

		@include minW(992) {
			height: auto !important;
			overflow: inherit;
		}
	}

	&__content {
		padding-bottom: vwm(32px);

		@include minW(640) {
			padding-bottom: vwt(64px);
		}

		@include minW(992) {
			padding-bottom: 0;
		}
	}
}