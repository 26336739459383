.tour-collapse {
	position: relative;
	margin-top: vwm(32px);

	@include minW(640) {
		margin-top: vwt(64px);
	}

	@include minW(992) {
		margin-top: 0;
	}

	&__title {
		position: relative;
		font-size: vwm(20px);
		padding-left: vwm(32px);

		@include minW(640) {
			font-size: vwt(40px);
			padding-left: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(28px);
			padding-left: vwd(32px);
		}

		@include minW(1440) {
			font-size: 28px;
			padding-left: 32px;
		}

		&::before {
			content: '';
			display: block;
			background-color: #c5c5c5;
			border-radius: 4px;
			position: absolute;
			left: 0;
			top: vwm(4px);
			width: vwm(20px);
			height: vwm(20px);

			@include minW(640) {
				top: vwt(8px);
				width: vwt(40px);
				height: vwt(40px);
			}

			@include minW(992) {
				top: vwd(8px);
				width: vwd(24px);
				height: vwd(24px);
			}

			@include minW(1440) {
				top: 8px;
				width: 24px;
				height: 24px;
			}
		}
	}

	&__item {
		border-bottom: 1px solid #e1d1b2;
		margin-bottom: 16px;

		&:last-child {
			border-bottom: 0;
		}

		&.is-show {
			.tour-collapse {
				&__body {
					display: block;
				}
			}
		}
	}

	&__body {
		display: none;
		overflow: hidden;
	}

	&__content {
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(16px);
		}

		@include minW(1440) {
			padding: 16px;
		}
	}
}
