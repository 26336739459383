.package-detail {
	position: relative;

	&__foot {
		text-align: right;
	}

	.accordion {
		&__content {
			padding-left: 0;
			padding-right: 0;
		}

		&__item {
			&:last-child {
				.accordion {
					&__head {
						border-color: transparent;
					}
				}
			}
		}
	}
}

.package-note {
	position: relative;
	padding-left: 32px;
	color: #808080;

	.icon-svg {
		position: absolute;
		left: 0;
		top: 0;
	}
}

.package-content {
	&__title {
		font-size: 24px;
	}
}

.quote-price {
	border: 1px solid #d9d9d9;
	border-radius: vwm(10px);
	padding: vwm(16px);
	font-size: vwm(16px);
	margin-bottom: vwm(16px);

	@include minW(640) {
		border-radius: vwt(20px);
		padding: vwt(32px);
		font-size: vwt(32px);
		margin-bottom: vwt(32px);
	}

	@include minW(992) {
		border-radius: vwd(20px);
		padding: vwd(16px);
		font-size: vwd(24px);
		margin-bottom: vwd(32px);
	}

	@include minW(1440) {
		border-radius: 20px;
		padding: 16px;
		font-size: 24px;
		margin-bottom: 32px;
	}

	&__content {
		color: #808080;
	}

	&__label {
		display: block;
	}

	&__profit {
		margin-bottom: 16px;
	}
}

.profit {
	position: relative;
	width: 100%;
	max-width: vwm(150px);

	@include minW(640) {
		max-width: vwt(300px);
	}

	@include minW(992) {
		max-width: 100%;
	}

	&__input {
		width: 100%;
		border: 1px solid #808080;
		padding: 4px 64px 4px 16px;
		border-radius: 10px;
		text-align: center;
	}

	&__change {
		font-size: 0;
		background: url('../img/icon-updown.svg') no-repeat center;
		width: 16px;
		height: 32px;
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		border: 0;
		outline: 0;
	}

	&__text {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 42px;
	}
}
