.taber {
  position: relative;

  &__menu {
    display: flex;
  }

  &__link {
    display: block;
    width: auto;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: $black-color;
    position: relative;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    margin-bottom: 30px;

    @include minW(1600) {
      font-size: 24px;
      margin-bottom: 40px;
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background-color: $yellow-color;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: width 0.3s ease-out;
    }

    @include hover {
      color: $yellow-color;

      &::after {
        width: 100%;
      }
    }

    &.is-active {
      color: $yellow-color;

      &::after {
        width: 100%;
      }
    }
  }

  &__content {
    position: relative;
    height: 0;
    opacity: 0;
    overflow: hidden;
    color: $black-color;

    &.is-active {
      opacity: 1;
      height: auto;
      overflow: unset;
    }

    .img {
      margin-bottom: 16px;
    }

    ul {
      list-style: none;

      li {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 12px;

        strong {
          min-width: 160px;
        }
      }
    }
  }

  &__thumb {
    font-size: 10px;

    p {
      margin-top: -10%;
    }
  }

  &--vertical {

    @include minW(992) {
      display: flex;
      flex-wrap: nowrap;
    }

    >.taber {
      &__menu {
        overflow-x: auto;
        margin-bottom: vwm(32px);

        @include minW(992) {
          display: block;
          max-width: 26%;
          flex-basis: 26%;
          margin-bottom: vwd(32px);
          padding-right: vwd(32px);
        }

        @include minW(1440) {
          margin-bottom: 32px;
          padding-right: 32px;
        }

        .taber {
          &__link {
            margin-right: vwm(32px);
            margin-bottom: vwm(16px);

            @include minW(992) {
              margin-right: 0;
              margin-bottom: vwd(32px);
            }

            @include minW(1440) {
              margin-bottom: 32px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &__body {
        @include minW(992) {
          max-width: 74%;
          flex-basis: 74%;
        }
      }
    }
  }

  &--people {
    .taber {
      &__menu {
        margin: 0 -12px;
        overflow-x: auto;
        white-space: nowrap;
      }

      &__link {
        padding: 0 12px;
        min-width: 88px;
      }
    }
  }

  &--year {
    .taber {
      &__menu {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__link {
        margin-right: 32px;
        margin-bottom: 24px;
        color: $black-color;

        &::after {
          display: none;
        }

        @include hover {
          strong {
            border-color: $yellow-color;
          }
        }

        &.is-active {
          strong {
            border-color: $yellow-color;
          }
        }

        strong {
          display: block;
          font-size: 20px;
          font-weight: 400;
          border: 1px solid $black-color;
          border-radius: 8px;
          padding: 8px;
        }
      }
    }
  }
}
