.card-vido {
	position: relative;

	&__thumb {
		display: block;
		height: vwm(460px);

		@include minW(640) {
			height: vwt(920px);
		}

		@include minW(992) {
			height: vwd(460px);
		}

		@include minW(1440) {
			height: 460px;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__body {
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(32px);
		}

		@include minW(1440) {
			padding: 32px;
		}
	}

	&__group {
		color: #808080;

		@include minW(992) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			margin-bottom: 16px;
		}
	}

	&__title {
		color: $black-color;
		font-size: vwm(14px);
		font-weight: 400;
		text-transform: uppercase;

		@include minW(640) {
			font-size: vwt(28px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}
	}

	&__location {
		position: relative;
		font-size: vwm(16px);
		margin-bottom: vwm(8px);
		padding-left: vwm(24px);

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(16px);
			padding-left: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			padding-left: vwd(24px);
			margin-bottom: 0;
		}

		@include minW(1440) {
			font-size: 16px;
			padding-left: 24px;
		}

		&::before {
			content: '';
			display: block;
			background: url('../img/icon-location-black.svg') no-repeat center;
			background-size: cover;
			width: vwm(16px);
			height: vwm(19px);
			position: absolute;
			left: 0;
			top: 0;

			@include minW(640) {
				width: vwt(32px);
				height: vwt(38px);
			}

			@include minW(992) {
				width: vwd(16px);
				height: vwd(19px);
			}

			@include minW(1440) {
				width: 16px;
				height: 19px;
			}
		}
	}

	&__price {
		display: flex;
		align-items: center;
		margin-bottom: 0;

		strong {
			font-size: vwm(20px);
			color: #000;
			padding-left: 8px;
			text-wrap: nowrap;

			@include minW(640) {
				font-size: vwt(20px);
			}

			@include minW(992) {
				font-size: vwd(20px);
			}

			@include minW(1440) {
				font-size: 20px;
			}
		}
	}
}
