.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($black-color, 0.5);
	display: none;
	z-index: 9999;

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

  &__icon {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: inline-block;
		border-top: 3px solid #FACD49;
		border-left: 3px solid #FACD49;
		border-right: 3px solid transparent;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}