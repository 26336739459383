.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba($black-color, 0.5);
	display: none;

	&__dialog {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		transform: translateX(100%);
		transition: transform .3s ease-out;
	}

	&__main {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: $white-color;
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			width: 50%;
			padding: vwd(32px);
		}

		@include minW(1440) {
			padding: 32px;
		}
	}

	&__close {
		position: absolute;
		background-color: transparent;
		border: 0;
		outline: 0;
		padding: vwm(6px);
		font-size: vwm(24px);
		right: vwm(32px);
		top: vwm(32px);
		display: block;
		z-index: 2;

		@include minW(640) {
			padding: vwt(12px);
			font-size: vwt(48px);
			right: vwt(64px);
			top: vwt(64px);
		}

		@include minW(992) {
			padding: vwd(6px);
			font-size: vwd(24px);
			right: vwd(32px);
			top: vwd(32px);
		}

		@include minW(1440) {
			padding: 6px;
			font-size: 24px;
			right: 32px;
			top: 32px;
		}

		&:focus {
			outline: 0;
		}
	}

	&__menu {
		display: flex;
		border-top: 1px dashed $black-color;
		padding-top: vwm(16px);

		@include minW(640) {
			padding-top: vwt(32px);
		}

		@include minW(992) {
			padding-top: vwd(32px);
		}

		@include minW(1440) {
			padding-top: 32px;
		}
	}

	&--show {
		.sidebar {
			&__dialog {
				transform: none;
			}
		}
	}

	a {
		color: $black-color;
		transition: color 0.3s ease-out;

		@include hover {
			color: $yellow-color;
		}
	}
}

.navmenu {
	list-style: none;
	padding: 0;
	margin: 0 64px 0 0;

	&:last-child {
		margin-right: 0;
	}

	&__item {
		margin-bottom: vwm(16px);

		@include minW(640) {
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			margin-bottom: vwd(32px);
		}

		@include minW(1440) {
			margin-bottom: 32px;
		}
	}
}