.container {
	width: 100%;
	padding: 0 vwm(16px);
	margin: 0 auto;

	@include minW(640) {
		padding: 0 vwt(32px);
	}

	@include minW(992) {
		max-width: vwd(1152px);
		padding: 0 vwd(16px);
	}

	@include minW(1440) {
		max-width: 1152px;
		padding: 0 16px;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 vwm(-16px);

	@include minW(640) {
		margin: 0 vwt(-32px);
	}

	@include minW(992) {
		margin: 0 vwd(-16px);
	}

	@include minW(1440) {
		margin: 0 -16px;
	}

	>* {
		box-sizing: border-box;
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding: 0 vwm(16px);

		@include minW(640) {
			padding: 0 vwt(32px);
		}

		@include minW(992) {
			padding: 0 vwd(16px);
		}

		@include minW(1440) {
			padding: 0 16px;
		}
	}

	&__col {
		flex: 1 0 0%;

		&--auto {
			flex: 0 0 auto;
			width: auto;
		}

		&--1 {
			flex: 0 0 auto;
			width: 8.33333333%;
		}

		&--2 {
			flex: 0 0 auto;
			width: 16.66666667%;
		}

		&--3 {
			flex: 0 0 auto;
			width: 25%;
		}

		&--4 {
			flex: 0 0 auto;
			width: 33.33333333%;
		}

		&--5 {
			flex: 0 0 auto;
			width: 41.66666667%;
		}

		&--6 {
			flex: 0 0 auto;
			width: 50%;
		}

		&--7 {
			flex: 0 0 auto;
			width: 58.33333333%;
		}

		&--8 {
			flex: 0 0 auto;
			width: 66.66666667%;
		}

		&--9 {
			flex: 0 0 auto;
			width: 75%;
		}

		&--10 {
			flex: 0 0 auto;
			width: 83.33333333%;
		}

		&--11 {
			flex: 0 0 auto;
			width: 91.66666667%;
		}

		&--12 {
			flex: 0 0 auto;
			width: 100%;
		}

		&--offset-1 {
			margin-left: 8.33333333%;
		}

		&--offset-2 {
			margin-left: 16.66666667%;
		}

		&--offset-3 {
			margin-left: 25%;
		}

		&--offset-4 {
			margin-left: 33.33333333%;
		}

		&--offset-5 {
			margin-left: 41.66666667%;
		}

		&--offset-6 {
			margin-left: 50%;
		}

		&--offset-7 {
			margin-left: 58.33333333%;
		}

		&--offset-8 {
			margin-left: 66.66666667%;
		}

		&--offset-9 {
			margin-left: 75%;
		}

		&--offset-10 {
			margin-left: 83.33333333%;
		}

		&--offset-11 {
			margin-left: 91.66666667%;
		}
	}
}

@media screen and (min-width: 576px) {
	.row {
		&__col--sm {
			flex: 1 0 0%;

			&-auto {
				flex: 0 0 auto;
				width: auto;
			}

			&-1 {
				flex: 0 0 auto;
				width: 8.33333333%;
			}

			&-2 {
				flex: 0 0 auto;
				width: 16.66666667%;
			}

			&-3 {
				flex: 0 0 auto;
				width: 25%;
			}

			&-4 {
				flex: 0 0 auto;
				width: 33.33333333%;
			}

			&-5 {
				flex: 0 0 auto;
				width: 41.66666667%;
			}

			&-6 {
				flex: 0 0 auto;
				width: 50%;
			}

			&-7 {
				flex: 0 0 auto;
				width: 58.33333333%;
			}

			&-8 {
				flex: 0 0 auto;
				width: 66.66666667%;
			}

			&-9 {
				flex: 0 0 auto;
				width: 75%;
			}

			&-10 {
				flex: 0 0 auto;
				width: 83.33333333%;
			}

			&-11 {
				flex: 0 0 auto;
				width: 91.66666667%;
			}

			&-12 {
				flex: 0 0 auto;
				width: 100%;
			}

			&-offset-1 {
				margin-left: 8.33333333%;
			}

			&-offset-2 {
				margin-left: 16.66666667%;
			}

			&-offset-3 {
				margin-left: 25%;
			}

			&-offset-4 {
				margin-left: 33.33333333%;
			}

			&-offset-5 {
				margin-left: 41.66666667%;
			}

			&-offset-6 {
				margin-left: 50%;
			}

			&-offset-7 {
				margin-left: 58.33333333%;
			}

			&-offset-8 {
				margin-left: 66.66666667%;
			}

			&-offset-9 {
				margin-left: 75%;
			}

			&-offset-10 {
				margin-left: 83.33333333%;
			}

			&-offset-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.row {
		&__col--md {
			flex: 1 0 0%;

			&-auto {
				flex: 0 0 auto;
				width: auto;
			}

			&-1 {
				flex: 0 0 auto;
				width: 8.33333333%;
			}

			&-2 {
				flex: 0 0 auto;
				width: 16.66666667%;
			}

			&-3 {
				flex: 0 0 auto;
				width: 25%;
			}

			&-4 {
				flex: 0 0 auto;
				width: 33.33333333%;
			}

			&-5 {
				flex: 0 0 auto;
				width: 41.66666667%;
			}

			&-6 {
				flex: 0 0 auto;
				width: 50%;
			}

			&-7 {
				flex: 0 0 auto;
				width: 58.33333333%;
			}

			&-8 {
				flex: 0 0 auto;
				width: 66.66666667%;
			}

			&-9 {
				flex: 0 0 auto;
				width: 75%;
			}

			&-10 {
				flex: 0 0 auto;
				width: 83.33333333%;
			}

			&-11 {
				flex: 0 0 auto;
				width: 91.66666667%;
			}

			&-12 {
				flex: 0 0 auto;
				width: 100%;
			}

			&-offset-1 {
				margin-left: 8.33333333%;
			}

			&-offset-2 {
				margin-left: 16.66666667%;
			}

			&-offset-3 {
				margin-left: 25%;
			}

			&-offset-4 {
				margin-left: 33.33333333%;
			}

			&-offset-5 {
				margin-left: 41.66666667%;
			}

			&-offset-6 {
				margin-left: 50%;
			}

			&-offset-7 {
				margin-left: 58.33333333%;
			}

			&-offset-8 {
				margin-left: 66.66666667%;
			}

			&-offset-9 {
				margin-left: 75%;
			}

			&-offset-10 {
				margin-left: 83.33333333%;
			}

			&-offset-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.row {
		&__col--lg {
			flex: 1 0 0%;

			&-auto {
				flex: 0 0 auto;
				width: auto;
			}

			&-1 {
				flex: 0 0 auto;
				width: 8.33333333%;
			}

			&-2 {
				flex: 0 0 auto;
				width: 16.66666667%;
			}

			&-3 {
				flex: 0 0 auto;
				width: 25%;
			}

			&-4 {
				flex: 0 0 auto;
				width: 33.33333333%;
			}

			&-5 {
				flex: 0 0 auto;
				width: 41.66666667%;
			}

			&-6 {
				flex: 0 0 auto;
				width: 50%;
			}

			&-7 {
				flex: 0 0 auto;
				width: 58.33333333%;
			}

			&-8 {
				flex: 0 0 auto;
				width: 66.66666667%;
			}

			&-9 {
				flex: 0 0 auto;
				width: 75%;
			}

			&-10 {
				flex: 0 0 auto;
				width: 83.33333333%;
			}

			&-11 {
				flex: 0 0 auto;
				width: 91.66666667%;
			}

			&-12 {
				flex: 0 0 auto;
				width: 100%;
			}

			&-offset-1 {
				margin-left: 8.33333333%;
			}

			&-offset-2 {
				margin-left: 16.66666667%;
			}

			&-offset-3 {
				margin-left: 25%;
			}

			&-offset-4 {
				margin-left: 33.33333333%;
			}

			&-offset-5 {
				margin-left: 41.66666667%;
			}

			&-offset-6 {
				margin-left: 50%;
			}

			&-offset-7 {
				margin-left: 58.33333333%;
			}

			&-offset-8 {
				margin-left: 66.66666667%;
			}

			&-offset-9 {
				margin-left: 75%;
			}

			&-offset-10 {
				margin-left: 83.33333333%;
			}

			&-offset-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.row {
		&__col--xl {
			flex: 1 0 0%;

			&-auto {
				flex: 0 0 auto;
				width: auto;
			}

			&-1 {
				flex: 0 0 auto;
				width: 8.33333333%;
			}

			&-2 {
				flex: 0 0 auto;
				width: 16.66666667%;
			}

			&-3 {
				flex: 0 0 auto;
				width: 25%;
			}

			&-4 {
				flex: 0 0 auto;
				width: 33.33333333%;
			}

			&-5 {
				flex: 0 0 auto;
				width: 41.66666667%;
			}

			&-6 {
				flex: 0 0 auto;
				width: 50%;
			}

			&-7 {
				flex: 0 0 auto;
				width: 58.33333333%;
			}

			&-8 {
				flex: 0 0 auto;
				width: 66.66666667%;
			}

			&-9 {
				flex: 0 0 auto;
				width: 75%;
			}

			&-10 {
				flex: 0 0 auto;
				width: 83.33333333%;
			}

			&-11 {
				flex: 0 0 auto;
				width: 91.66666667%;
			}

			&-12 {
				flex: 0 0 auto;
				width: 100%;
			}

			&-offset-1 {
				margin-left: 8.33333333%;
			}

			&-offset-2 {
				margin-left: 16.66666667%;
			}

			&-offset-3 {
				margin-left: 25%;
			}

			&-offset-4 {
				margin-left: 33.33333333%;
			}

			&-offset-5 {
				margin-left: 41.66666667%;
			}

			&-offset-6 {
				margin-left: 50%;
			}

			&-offset-7 {
				margin-left: 58.33333333%;
			}

			&-offset-8 {
				margin-left: 66.66666667%;
			}

			&-offset-9 {
				margin-left: 75%;
			}

			&-offset-10 {
				margin-left: 83.33333333%;
			}

			&-offset-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}

@media screen and (min-width: 1400px) {
	.row {
		&__col--xxl {
			flex: 1 0 0%;

			&-auto {
				flex: 0 0 auto;
				width: auto;
			}

			&-1 {
				flex: 0 0 auto;
				width: 8.33333333%;
			}

			&-2 {
				flex: 0 0 auto;
				width: 16.66666667%;
			}

			&-3 {
				flex: 0 0 auto;
				width: 25%;
			}

			&-4 {
				flex: 0 0 auto;
				width: 33.33333333%;
			}

			&-5 {
				flex: 0 0 auto;
				width: 41.66666667%;
			}

			&-6 {
				flex: 0 0 auto;
				width: 50%;
			}

			&-7 {
				flex: 0 0 auto;
				width: 58.33333333%;
			}

			&-8 {
				flex: 0 0 auto;
				width: 66.66666667%;
			}

			&-9 {
				flex: 0 0 auto;
				width: 75%;
			}

			&-10 {
				flex: 0 0 auto;
				width: 83.33333333%;
			}

			&-11 {
				flex: 0 0 auto;
				width: 91.66666667%;
			}

			&-12 {
				flex: 0 0 auto;
				width: 100%;
			}

			&-offset-1 {
				margin-left: 8.33333333%;
			}

			&-offset-2 {
				margin-left: 16.66666667%;
			}

			&-offset-3 {
				margin-left: 25%;
			}

			&-offset-4 {
				margin-left: 33.33333333%;
			}

			&-offset-5 {
				margin-left: 41.66666667%;
			}

			&-offset-6 {
				margin-left: 50%;
			}

			&-offset-7 {
				margin-left: 58.33333333%;
			}

			&-offset-8 {
				margin-left: 66.66666667%;
			}

			&-offset-9 {
				margin-left: 75%;
			}

			&-offset-10 {
				margin-left: 83.33333333%;
			}

			&-offset-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}