.reservation {
	position: relative;
	overflow: hidden;
	background: url('../img/bg-contact.png') no-repeat bottom center;
	background-size: cover;
	text-align: center;
	padding: vwm(64px) 0;
	font-size: vwm(20px);

	@include minW(640) {
		padding: vwt(128px) 0;
		font-size: vwt(40px);
	}

	@include minW(992) {
		padding: vwd(128px) 0;
		font-size: vwd(24px);
	}

	@include minW(1440) {
		padding: 128px 0;
		font-size: 24px;
	}

	&__title {
		color: #fff;
		font-size: vwm(44px);
		margin-bottom: vwm(64px);

		@include minW(640) {
			font-size: vwt(44px);
			margin-bottom: vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(44px);
			margin-bottom: vwd(64px);
		}

		@include minW(1440) {
			font-size: 44px;
			margin-bottom: 64px;
		}
	}

	&__body {
		background-color: #fff;
		padding: vwm(64px) vwm(32px);

		@include minW(640) {
			padding: vwt(128px) vwt(64px);
		}

		@include minW(992) {
			padding: vwd(128px);
		}

		@include minW(1440) {
			padding: 128px;
		}

		.img {
			width: vwm(80px);
			margin: 0 auto vwm(32px);

			@include minW(640) {
				width: vwt(160px);
				margin: 0 auto vwt(32px);
			}

			@include minW(992) {
				width: vwd(100px);
				margin: 0 auto vwd(32px);
			}

			@include minW(1440) {
				width: 100px;
				margin: 0 auto 32px;
			}
		}
	}

	&__subtitle {
		font-size: vwm(24px);

		@include minW(640) {
			font-size: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(40px);
		}

		@include minW(1440) {
			font-size: 40px;
		}
	}
}