.page {
  &--padtop {
    padding-top: vwm(134px);

    @include minW(640) {
      padding-top: vwt(134px);
    }

    @include minW(992) {
      padding-top: vwd(134px);
    }

    @include minW(1440) {
      padding-top: 134px;
    }
  }

  &--home {
    padding-top: 0;
  }
}

.close {
  background-color: transparent;
  border: 0;
  outline: 0;
}